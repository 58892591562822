// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import '~@fortawesome/fontawesome-free/css/all.css';

// Quill
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css'; //theme
